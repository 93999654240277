/*
CSS específico para o TikiCMS 2
*/

h1, h2, h3                          { line-height: 35px !important; }
.sf-toolbar                         { /*display:none !important;*/ }
.align-center                       { text-align:center !important; }
.bt-icon                            { width:14px; height:14px; text-indent:-9999em; overflow:hidden; }
.clear, .dropzone .dz-preview .dz-progress { clear:both; }
.clearfix:after                     { clear:both; content: "."; display:block; height: 0; visibility: hidden; font-size: 0; } /* float clearing for everyone else */
.nospace                            { width:1px; white-space:nowrap; }
.relative                           { position:relative; }

/* header */
.user_bar                           { width:65px !important; }
.webmonsters                        { display:block; width:20px; height:20px; overflow:hidden; text-indent:-9999em; background:url('../images/bts/webmonsters_icone.png') no-repeat 0 0; margin:10px 0; }
.dropdown-usuario                   { width:15px; text-indent:-9999em; overflow:hidden; background:url('../images/bts/usuario.png') no-repeat center; }

/* content */

.row-fluid .content                 { padding:8px 29px 29px 29px; margin-bottom:20px; border:1px solid #e5e5e5; border-radius:5px; -webkit-border-radius:5px; -moz-border-radius:5px; box-shadow:0 1px 2px rgba(0,0,0,.05); -webkit-box-shadow:0 1px 2px rgba(0,0,0,.05); -moz-box-shadow:0 1px 2px rgba(0,0,0,.05); }
.home .row-fluid .content           { height:255px; }
.home .row-fluid .content ul        { list-style: none; margin:0; padding:0; }
.home .row-fluid .content ul li     { margin-bottom:12px; font-size:12px; }
.home .row-fluid .content ul p      { margin-bottom:0px; font-size:12px; }

.table                              { font-size:12px; margin-bottom:10px !important; }
.table thead                        {
    background-color: #dedede;
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#ececec), to(#dcdcdc));
    background-image: -webkit-linear-gradient(top, #ececec, #dcdcdc);
    background-image:    -moz-linear-gradient(top, #ececec, #dcdcdc);
    background-image:     -ms-linear-gradient(top, #ececec, #dcdcdc);
    background-image:      -o-linear-gradient(top, #ececec, #dcdcdc);
    font-weight: bold;
    text-shadow:1px 1px 0 #fff;
}
.table input[type="checkbox"]       { margin:0; }

.breadcrumb                         { background:none; margin:12px 0 0 0; }

/* Listagem */
.wrap_filter                        { display:inline-block; }
.trigger_filter_advanced            { margin-left:10px; }
form.inline { display: inline; }

.table thead tr a                   { display:block; color:#333; padding-right: 12px; }
.table thead tr a:hover             { text-decoration:none; background:url('../images/bts/seta_ordenar_baixo.png') no-repeat right; }
.table thead tr th.createdAt,
.table thead tr th.updatedAt { width:105px; }
.table a.icon                       { text-indent:-9999em; overflow:hidden; margin-left:5px; }
.table .status                      { display:block; }
.table .status.verde                { background:url('../images/bgs/status_verde.png') no-repeat right 3px; }
.table .status.amarelo              { background:url('../images/bgs/status_amarelo.png') no-repeat right 3px; }
.table .status.vermelho             { background:url('../images/bgs/status_vermelho.png') no-repeat right 3px; }

.container_balloon                  { position:relative; }
.container_balloon p                    { margin:0; }
    .balloon                            { min-width:155px; position:absolute; bottom:30px; left:50%; background:#fff; border:1px solid #a5a5a5; border-radius:5px; -moz-border-radius:5px; -webkit-border-radius:5px; box-shadow:0 0 10px rgba(0,0,0,.2); }
    .balloon > div                  { padding:14px; }
    .balloon .title                     { background:#f0f0f0; border-bottom:1px solid #e1e1e1; position:relative; border-radius:5px 5px 0 0; }
    .balloon .title .close          { width:9px; height:9px; display:block; text-indent:-9999em; overflow:hidden; position:absolute; top:20px; right:15px; background:url('../images/bts/close.png') no-repeat 0 0; z-index:10; }
    .balloon .point_balloon             { width:24px; height:12px; background:url('../images/bgs/point_balloon_top.png') no-repeat 0 0; position:absolute; bottom:-12px; left:50%; margin-left:-12px; }

.wrap_filter                        { position:relative; }
    .filter_advanced                { display:none; }
    .filter_advanced .title p       { margin-bottom:0; }
    .filter_advanced                { position:absolute; top:30px; left:50%; z-index:10; background:#fff; border:1px solid #a5a5a5; border-radius:5px; -moz-border-radius:5px; -webkit-border-radius:5px; box-shadow:0 0 10px rgba(0,0,0,.2); }
    .filter_advanced > div          { padding:14px; }
    .filter_advanced .title         { background:#f0f0f0; border-bottom:1px solid #e1e1e1; position:relative; border-radius:5px 5px 0 0; }
    .filter_advanced .title .close { width:9px; height:9px; display:block; text-indent:-9999em; overflow:hidden; position:absolute; top:20px; right:15px; background:url('../images/bts/close.png') no-repeat 0 0; z-index:10; }
    .filter_advanced .point_balloon     { width:24px; height:12px; background:url('../images/bgs/point_balloon_top.png') no-repeat 0 0; position:absolute; top:-10px; left:50%; margin-left:-12px; }
    .filter_advanced label          { font-size:11px; display:block; }
    .filter_advanced .grid220       { width:220px; }
    .filter_advanced .grid440       { width:440px; }
    .filter_advanced .btn           { margin:10px 0 5px 0; }

    /* Filtro em duas colunas */
    .filter-columns > div           { margin-right: -20px; width: 480px; }
    .filter-columns > div > div    { float: left; margin-right: 20px; margin-bottom: 15px; }
    .filter-columns > div > div:nth-child(2n+1) { clear: left; }


.tags                               { background:#ececec; border-radius:5px; -moz-border-radius:5px; -webkit-border-radius:5px; margin-bottom:20px; padding:6px; }
.tags ul                            { list-style:none; margin:0; }
.tags ul li                         { float:left; background:#fff; border-radius:5px; -moz-border-radius:5px; -webkit-border-radius:5px; border:1px solid #d9d9d9; background:#fff url('../images/bgs/degrade.png') repeat-x bottom; margin-right:5px; }
.tags ul li p                       { font-size:12px; margin:0; float:left; background:url('../images/bgs/tag.png') no-repeat 10px 10px; padding:6px 6px 6px 30px; }
.tags ul li a                       { font-size:12px; margin:0; float:left; border-left:1px solid #ebebeb; display:block; text-indent:-9999em; overflow:hidden; padding:9px; background:url('../images/bts/close.png') no-repeat center; width:9px; height:12px; }
.tags ul .clear_filter          { width:16px; height:16px; display:block; text-indent:-9999em; overflow:hidden; background:url('../images/bts/clear_filter.png') no-repeat 0 0; margin:8px 5px; float:left; }
.tags ul .clear_filter:hover        { background:url('../images/bts/clear_filter.png') no-repeat 0 -16px; }

.pagination                         { margin:0 !important; }

/* Leitura / Edição */

.edit_content                       { border:1px solid #d1d1d1; margin-top:15px; }
.edit_content .tabs                     { background:url('../images/bgs/bg_tabs.gif') repeat-x 0 0; list-style:none; margin:0; height:47px; border-bottom:1px solid #d1d1d1; }
.edit_content .tabs li              { float:left; }
.edit_content .tabs li a            { color:#333; font-size:15px; font-weight:bold; text-decoration: none; padding:14px 18px; display:block; border-right:1px solid #d1d1d1; }
.edit_content .tabs li.current a,
.edit_content .tabs li:hover a      { background:#fff; box-shadow: inset 0 3px 0 #0083c9; -moz-box-shadow: inset 0 3px 0 #0083c9; -webkit-box-shadow: inset 0 3px 0 #0083c9; border:0; }
.edit_content .container            { padding:20px; }
.edit_content label                     { font-size:12px; font-weight:bold; }
.edit_content label span                { font-weight:normal; }

.mini input,
.mini select,
.mini textarea                      { width:40px; }

.small input,
.small select,
.small textarea                     { width:90px; }

.medium input,
.medium select,
.medium textarea                    { width:140px; }

.large input,
.large select,
.large textarea                     { width:360px; }

.large input,
.large select,
.large textarea                     { width:360px; }

.xlarge input,
.xlarge select,
.xlarge textarea                    { width:680px; }

.full input,
.full select,
.full textarea                      { width:100%; }

.font_large input,
.font_large select,
.font_large textarea                { font-size:18px; }

.error input,
.error select,
.error textarea                     { border:1px solid red; }
.error label                        { color:red; }
.validation_errors li               { color:red; }

.input textarea                     { height:140px; }
.input-extra-mini                   { width:30px; }

.multiplos p                        { margin-right: 5px; }
.multiplos input                    { margin-bottom:0; }

.progresso                          { width:255px; border:1px solid #e1e1e1; padding:10px; margin:10px 0 20px 0; border-radius:5px; -moz-border-radius:5px; -webkit-border-radius:5px; font-size:11px; }
.progress                           { margin-bottom:0; }

.observation                        { position:relative; padding:40px 20px 20px; background:#f7f7f7; border:1px solid #e1e1e1; margin-bottom:30px; border-radius:3px; -moz-border-radius:3px; -webkit-border-radius:3px; }
.observation span                   { position:absolute; top:0; left:0; background:#0083c9; border-radius:3px 0 3px 0; -moz-border-radius:3px 0 3px 0; -webkit-border-radius:3px 0 3px 0; font-size:12px; color:#fff; font-weight:bold; padding:3px 7px; }
.observation ul                     { list-style: none; margin:0; }
.observation ul li                  { font-size:12px; }

.edit_images p                  { font-size:12px; margin-bottom:0; }

.list_images                                        { list-style: none; margin:0; }
.list_images li                                     { width:151px; max-height:500px; float:left; margin-right:15px; }
.list_images li .image_container                    { cursor:move; background:#fff; border:1px solid #e0e0e0; width:140px; height:160px; display:block; padding:5px; box-shadow:1px 1px 3px rgba(0,0,0,.1); margin:18px 0 6px 0; }
.list_images li .face_image_container               { cursor:move; background:#fff; border:1px solid #e0e0e0; width:140px; max-height:400px; display:block; padding:5px; box-shadow:1px 1px 3px rgba(0,0,0,.1); margin:18px 0 6px 0; }
.list_images li .face_image_container img           { width: auto; height: auto; }
.list_images li .selected							{ border:1px solid #005580 !important; }
.list_images li .image_container img:hover			{ cursor:move; }
.list_images li .image_container input				{ margin:6px 5px 5px 5px; display:block; }
.list_images li .add_legend,
.list_images li .add_date,
.list_images li .add_legend_pt,
.list_images li .add_legend_en,
.list_images li .add_legend_es,
.list_images li .add_title,
.list_images li .add_legend3,
.list_images li .add_legend4,
.list_images li .add_legend5,
.list_images li .add_legend6						{ display:block; font-size:12px; text-align:center; margin:5px 0; }
.list_images li input								{ margin-top:10px; }
.list_images li .edit_legend a,
.list_images li .edit_date a,
.list_images li .edit_legend_pt a,
.list_images li .edit_legend_en a,
.list_images li .edit_legend_es a,
.list_images li .edit_title a,
.list_images li .edit_legend3 a,
.list_images li .edit_legend4 a,
.list_images li .edit_legend5 a,
.list_images li .edit_legend6 a						{ display:block; text-align:center; width:142px; height:24px; line-height:24px; padding:0 5px; font-size:11px; text-decoration:none; cursor:text; color:#000; overflow:hidden; }
.list_images li .edit_date a:hover,
.list_images li .edit_legend a:hover,
.list_images li .edit_legend_pt a:hover,
.list_images li .edit_legend_en a:hover,
.list_images li .edit_legend_es a:hover,
.list_images li .edit_title a:hover,
.list_images li .edit_legend3 a:hover,
.list_images li .edit_legend4 a:hover,
.list_images li .edit_legend5 a:hover,
.list_images li .edit_legend6 a:hover				{ background-color:#dbe5ff; }
.list_images li .create_legend input[name=legend],
.list_images li .create_date input[name=date],
.list_images li .create_legend_pt input[name=legendPt],
.list_images li .create_legend_en input[name=legendEn],
.list_images li .create_legend_es input[name=legendEs],
.list_images li .create_title input[name=title],
.list_images li .create_legend3 input[name=legend3],
.list_images li .create_legend4 input[name=legend4],
.list_images li .create_legend5 input[name=legend5],
.list_images li .create_legend6 input[name=legend6],
.list_images li .edit_date input[name=date],
.list_images li .edit_legend input[name=legend],
.list_images li .edit_legend_pt input[name=legendPt],
.list_images li .edit_legend_en input[name=legendEn],
.list_images li .edit_legend_es input[name=legendEs],
.list_images li .edit_title input[name=title],
.list_images li .edit_legend3 input[name=legend3],
.list_images li .edit_legend4 input[name=legend4],
.list_images li .edit_legend5 input[name=legend5],
.list_images li .edit_legend6 input[name=legend6]	{ position:relative; margin:0 0 0 -1px; width:140px; font-size:11px; height:1.2em; }

/*
Estes styles precisam ser mais específicos para não afetar outros formulários.
 */
/*
p.file input                            { border:0; background:none; }
p.file                                  { position:relative; height:31px; }
p.file .input_file_true                 { display:block; position:absolute; bottom:0; left:0; z-index:2; width:160px; height:31px; margin:0; font-size:20px; opacity:0; -moz-opacity:0; filter:alpha(opacity=0); }
*/
/*.ie9 p.file .input_file_true          { display:block; position:absolute; bottom:0; left:0; z-index:2; width:160px;  height:31px; margin:0; font-size:20px; opacity:0; -moz-opacity:0; filter:alpha(opacity=0); }*/
/*p.file .input_file_false              {  position:relative; z-index:1; background:url('../images/bts/campo_file.png') no-repeat;  height:33px; padding:0 10px; border:0; text-indent: -9999em; overflow:hidden; box-shadow:0; box-shadow:none; }
.ie p.file .input_file_true             { font-size:18px; width:529px; }*/

.datepicker input { width: 72px; float:left; margin-right: 10px; }
.datepicker div { float:left; }
.datepicker select { width: 56px; }

/* footer */

.footer .content                    { width:auto; padding:0 20px; }
.footer .content p                  { font-size:11px; }
.footer .content .tiki              { display:block; width:15px; height:5px; text-indent:-9999em; overflow:hidden; background:url('../images/bts/tiki.png') no-repeat 0 0; }

/* Login */

.form-signin {
max-width: 300px;
padding: 19px 29px 29px;
margin: 0 auto 20px;
background-color: #fff;
border: 1px solid #e5e5e5;
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
            border-radius: 5px;
    -webkit-box-shadow: 0 1px 2px rgba(0,0,0,.05);
       -moz-box-shadow: 0 1px 2px rgba(0,0,0,.05);
            box-shadow: 0 1px 2px rgba(0,0,0,.05);
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
    margin-bottom: 20px;
}
.form-signin input[type="text"],
.form-signin input[type="password"] {
    font-size: 16px;
    height: auto;
    margin-bottom: 15px;
    padding: 7px 9px;
}

.form-signin .form-signin-heading   { width:295px; height:90px; margin-top: 0px; display:block; text-indent:-9999em; overflow:hidden; background:url('../images/bgs/logo.png') no-repeat center; background-size: 70% }

/* feedback */

/*
.feedback                           { width:100%; height:100%; position:absolute; top:0; left:0; background:url('../images/bgs/overlay.png'); z-index:100; }
.feedback .texto                    { width:300px; padding:50px 20px 20px 20px; border:1px solid #747474; position:absolute; left:50%; top:50%; -moz-border-radius:8px; -webkit-border-radius:8px; border-radius:8px; -moz-box-shadow:0 0 23px rgba(0,0,0,.5); -webkit-box-shadow:0 0 23px rgba(0,0,0,.5); box-shadow:0 0 23px rgba(0,0,0,.5); text-align:center; margin:-56px 0 0 -171px; }
.feedback .texto.loading            { background:#fff url('../images/bgs/loading.gif') no-repeat center 20px; }
.feedback .texto.success            { background:#fff url('../images/bgs/success.png') no-repeat center 20px; }
.feedback .texto.success h4         { color:#75ab00 !important; }
.feedback .texto.error              { background:#fff url('../images/bgs/error.png') no-repeat center 20px; }
.feedback .texto.error h4           { color:#c0330d !important; }
*/

.feedback                           { width:100%; height:32px; text-align:center; margin-top:-20px; position:fixed; z-index:1000 !important; }
.feedback p                         { margin:0 auto; display:inline-block; background:#ffecb1; border:1px solid #f6d87b; padding:5px 20px; font-weight:bold; border-radius: 0 0 3px 3px; -webkit-border-radius:0 0 3px 3px; -moz-border-radius:0 0 3px 3px; z-index:1000 !important; }
.ie .feedback p                     { display: inline; zoom: 1; }
.feedback p.success                 { background:#e4ffb1; border:1px solid #aecf70; }
.feedback p.error                   { background:#df0000; color:#fff; border:1px solid #970000; }
.feedback .error p a                { color:#ffbdbd; }

/* media queries */

@media (max-width: 1060px) {
    .user_bar                       { width:65px !important; }
    .webmonsters                    { display:block; width:20px; height:20px; overflow:hidden; text-indent:-9999em; background:url('../images/bts/webmonsters_icone.png') no-repeat 0 0; margin:10px 0;}
}

.btn-margin-fix                     { margin-bottom:25px !important }

/* Mensagens úteis em formulários */
.alert                              { font-weight:normal; font-style:normal; font-weight:bold; color:#ff0000; }
#confirmacao                        { width:300px; margin-left:-150px !important; }

/* Papéis */
select#tiki_adminbundle_papeltype_permissions { height: 300px; }

form div div label { clear: both; }

/* Dashboard */
#chart_div                                          { width: 100%; height: 475px;}
#chart_div g text                                   { font-size:10px;}
#chart_div svg                                      { position:absolute; top:0; left:0; width:100%;}

.dashboard a,
.dashboard a:hover                                  { transition:background-position 0s linear 0s;}

.dashboard .well                                    { background:none; padding:0;}
.dashboard .well h3                                 { background-color:#efefef; font-size: 18px; padding-left:16px; margin:0; font-weight: normal;}

.dashboard .chart-container                         { position:relative;}
.dashboard .pager-container                         { position:absolute; top:30px; left:20px; z-index: 100; display: inline-block;}
.dashboard .pager-container small                   { font-size: 22px; position:absolute; top:25px; left:105px;}
.dashboard .pager a                                 { color:#000; border-radius:5px;}
.dashboard .pager a:hover                           { background-position:0 -30px;}
.dashboard .pager li + li                           { margin-left:140px;}

.dashboard .nav-pills-container                     { position:absolute; top:55px; right:20px; z-index: 100;}
.dashboard .nav-pills-container a                   { color:#000;}
.dashboard .nav-pills-container a:hover             { background-position:0 -30px;}
.dashboard .nav-pills-container .active a           { background-color:#08C; color:#FFF;}

.dashboard .list-info                               { text-align: center;}
.dashboard .list-info ul                            { list-style: none; margin:0; min-height: 210px;}
.dashboard .list-info .primary > li                 { width:33.1%; display: inline-block; vertical-align: top;}
.dashboard .list-info .list-info-content h3         { height: 33px; line-height: 33px; padding:0 10px; margin-bottom: 10px; text-align: left;}
.dashboard .list-info .list-info-content hr         { border:none; border-bottom:1px solid #000; margin:0 auto 10px;}
.dashboard .list-info .secundary                    { padding:0 10px;}
.dashboard .list-info .secundary li                 { height: 40px; line-height: 40px; border-bottom:1px solid #dddddd; font-size: 12px; position:relative; text-align: left;}
.dashboard .list-info .secundary li:last-child      { border:none;}
.dashboard .list-info small                         { width:auto; height: 20px; line-height: 20px; display: block; position:absolute; top:10px; right:10px; padding:0 5px; background-color:#EFEFEF; border-radius:5px;}

.dashboard .nav-tabs                                { min-height:inherit !important; margin:0 0 10px 0 !important;}
.dashboard .nav-tabs a                              { font-weight: 600;}
.dashboard .nav-tabs a:hover                        { color:#000;}

/* Bootstrap Fixes */
.fix-checkbox label					                { display: inline-block; font-weight: normal; width: 150px; }
.fix-checkbox label:nth-child(6n)                   { display: inline; width: auto; }
.fix-checkbox label:nth-child(6n):after             { content: ""; display: block; height: 0; }

.fix-checkbox input[type="radio"],
.fix-checkbox input[type="checkbox"]				{ margin: 0 5px 10px 0 !important; position: relative; top: 0.2em; }


.selecionar-todos                                   { width:300px; display: inline-block; margin-left:5px; line-height:40px; vertical-align: -2px;}

label[for="a_product_card_information"] + ul        { list-style: none; margin-left:0;}
label[for="a_product_card_information"] + ul li     { color:#F33;}

label[for="a_product_card_observation"] + ul        { list-style: none; margin-left:0;}
label[for="a_product_card_observation"] + ul li     { color:#F33;}

.vich-file div { padding-top: 10px; }
.vich-file div label { display: inline; position: relative; top: 0.2em; }
.vich-file div input[type="checkbox"] { width: 15px; margin-right: 2px; display: inline; float: left; }

@font-face {
    font-family: "Glyphicons Halflings";
    src: url("../fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/glyphicons-halflings-regular.woff") format("woff"), url("../fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg");
}

@media not all {
	@font-face {
		font-family: "Glyphicons Halflings";
		src: url("../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg");
	}
}

.dropzone {
    background: none repeat scroll 0 0 #f2f2f2;
    border: 1px dashed #b7b7b7;
    border-radius: 5px;
    margin: 15px 0;
}
.dropzone.dz-drag-hover {
    background: none repeat scroll 0 0 #fffbb5;
}
.dropzone input[type="file"] {
    display: none;
}
.dropzone .dz-message {
    font-family: "Open Sans",Arial,Helvetica,sans-serif;
    font-size: 16px;
    font-weight: 300;
    padding: 20px;
    text-align: center;
}
.dropzone .dz-message span {
    cursor: pointer;
    padding: 0 0 0 20px;
    position: relative;
}
.dropzone .dz-message span::before {
    content: "\e027";
    font-family: "Glyphicons Halflings";
    left: 0;
    position: absolute;
    top: 3px;
}
.dropzone .dz-preview {
    background: none repeat scroll 0 0 #fff;
    box-shadow: 1px 1px 0 rgba(1, 1, 1, 0.1);
    margin: 0 20px 10px;
    padding: 10px;
    position: relative;
}
.dropzone .dz-preview .dz-details {
    padding: 0 0 0 20px;
}
.dropzone .dz-preview .dz-details .dz-filename, .dropzone .dz-preview .dz-details .dz-size {
    float: left;
    margin: 0 10px 10px 0;
}
.dropzone .dz-preview .dz-details .dz-size {
    color: #b4b4b4;
}
.dropzone .dz-preview .dz-progress {
    background: none repeat scroll 0 0 #fff;
    height: 5px;
    margin: 0 -10px -10px;
}
.dropzone .dz-preview .dz-progress .dz-upload {
    background: none repeat scroll 0 0 transparent;
    display: block;
    height: 5px;
}
.dropzone .dz-preview .dz-error-mark {
    display: none;
    height: 14px;
    width: 14px;
}
.dropzone .dz-preview .dz-error-mark span {
    display: none;
}
.dropzone .dz-preview .dz-error-mark::before {
    color: #ef0d0d;
    content: "\e014";
    font-family: "Glyphicons Halflings";
}
.dropzone .dz-preview .dz-remove {
    color: #aeaeae;
    font-size: 11px;
    padding: 0 0 0 15px;
    position: absolute;
    right: 10px;
    top: 13px;
}
.dropzone .dz-preview .dz-remove::before {
    content: "\e014";
    font-family: "Glyphicons Halflings";
    left: 0;
    position: absolute;
    top: 1px;
}
.dropzone .dz-preview .dz-remove:hover {
    text-decoration: none;
}
.dropzone .dz-preview img {
    display: none;
}
.dropzone .dz-preview .dz-success-mark {
    background: url("../img/elements/uploading.gif") no-repeat scroll 0 0 rgba(0, 0, 0, 0);
    height: 14px;
    left: 10px;
    position: absolute;
    top: 12px;
    width: 14px;
}
.dropzone .dz-preview .dz-success-mark span {
    display: none;
}
.dropzone .dz-preview:last-child {
    margin: 0 20px 20px;
}
.dropzone .dz-processing .dz-upload {
    background: none repeat scroll 0 0 #67cd3d !important;
}
.dropzone .dz-success .dz-success-mark {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
}
.dropzone .dz-success .dz-success-mark::before {
    color: #67cd3d;
    content: "\e013";
    font-family: "Glyphicons Halflings";
}
.m-b-0 {
    margin-bottom: 0px !important;
}

.fix-checkbox-large label							        { vertical-align: middle; display: inline-block; font-weight: normal; width: 300px; }
.fix-checkbox-large label:nth-child(6n)       { display: inline; width: auto; }
.fix-checkbox-large label:nth-child(6n):after { content: ""; display: block; height: 0; }

.fix-checkbox-large input[type="radio"],
.fix-checkbox-large input[type="checkbox"]		{ margin: 0 5px 10px 0 !important; position: relative; top: 0.2em; }
